import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import { Provider } from "react-redux";
import "./fonts/raleway.regular.ttf";
import "./fonts/raleway.extrabold.ttf";
import "./fonts/raleway.semibold.ttf";

// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://8de0f6ed292c4d8cb18b5db918ef8efd@o1343579.ingest.sentry.io/4504575958450176",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   environment: process.env.REACT_APP_FANFIRE_DEPLOYMENT_ENV ?? "unknown",
//   tracesSampleRate: 1.0,
// });

if (process.env.REACT_APP_FANFIRE_DEPLOYMENT_ENV === "production") {
  console.log = () => {};
  console.error = (error) => {
    console.debug(error);
    // console.error(error);
    // Sentry.captureException(error);
  };
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
